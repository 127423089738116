<template>
  <div class="error-area">
    <div>
      <div class="error-msg">
        <div class="img-area">
          <img src="/src/assets/images/gs_sendy_secondary.png" alt="" />
        </div>
        <strong> 현재 페이지에 접근할 수 없습니다. </strong>
        <p>
          요청하신 주소가 잘못되었거나 서버 또는 네트워크 오류가 발생한 것 같습니다.<br />
          혹은 권한과 접속 방법 및 연결 상태를 확인한 후 다시 시도해 주세요.<br />
          문제가 지속될 경우 관리자(sendy@gsretail.com)에게 문의해 주시기 바랍니다.
        </p>
        <GSButton
          :class="'select-date-today-btn'"
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.MEDIUM"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :text="'메인으로 이동'"
          width="114"
          @click="goMain"
        ></GSButton>
      </div>
      <div class="error-img">
        <div class="error-code">
          <span>{{ errorCode }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import GSButton from '@/components/ui/GSButton.vue'
import * as Constants from '@/constants/index'
import { useRouter } from 'vue-router'

const errorCode = 'Error'
const router = useRouter()

const goMain = () => {
  router.push({ name: 'sendingstatistics' })
}
</script>
