export function formatNumberWithComma(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/* Date -> YYYY.MM.DD */
export function convertDateToCommaDate(date: Date): string {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}.${month}.${day}`
}

/*  YYYY.MM.DD -> Date */
export function convertCommaDateToDate(dateString: string): Date {
  const [year, month, day] = dateString.split('.').map(Number)
  return new Date(year, month - 1, day)
}

/*  오늘날짜 return Date */
export function getCurrentDate(): Date {
  return new Date()
}

/*  오늘날짜 return YYYY.MM.DD */
export function getCurrentCommaDate(): string {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')

  return `${year}.${month}.${day}`
}
