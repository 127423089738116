/// <reference types="vite/client" />

export default class env {
  public base_url: string = import.meta.env.BASE_URL
  // public dev: boolean = import.meta.env.DEV
  public dev: boolean = import.meta.env.VITE_APP_NODE_ENV == 'dev' ? true : false
  public mode: string = import.meta.env.MODE
  public prod: boolean = import.meta.env.PROD
  public ssr: boolean = import.meta.env.SSR
  public processMode: string = import.meta.env.VITE_APP_NODE_ENV
  public url: string = import.meta.env.VITE_APP_BASE_API
}
