export const CHECKBOX_COLORS = {
  PRIMARY: 'gsr-chk-primary',
  SECONDARY: 'gsr-chk-secondary',
  INHERIT: 'gsr-chk-inherit'
} as const

export const CHECKBOX_SIZES = {
  SMALL: 'gsr-chk-small',
  MEDIUM: 'gsr-chk-medium',
  LARGE: 'gsr-chk-large'
} as const

// 타입 정의
export type CheckboxColors = (typeof CHECKBOX_COLORS)[keyof typeof CHECKBOX_COLORS]
export type CheckboxSizes = (typeof CHECKBOX_SIZES)[keyof typeof CHECKBOX_SIZES]
