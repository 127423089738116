export const INPUT_VARIANTS = {
  OUTLINED: 'gsr-input-outlined',
  STANDARD: 'gsr-input-standard'
} as const

export const INPUT_SIZES = {
  SMALL: 'gsr-input-small',
  MEDIUM: 'gsr-input-medium',
  LARGE: 'gsr-input-large'
} as const

export const INPUT_POSITION = {
  DEFAULT: 'gsr_input_default_position',
  LEFT: 'gsr_input_left_position',
  TOP: 'gsr_input_top_position'
} as const

export const INPUT_VALIDTYPE = {
  SYNC: 'sync',
  MENUAL: 'menual'
} as const

export const INPUT_VALIDSTYLE = {
  BASIC: 'basic',
  TABLE: 'table'
} as const

// 타입 정의
export type InputSizes = (typeof INPUT_SIZES)[keyof typeof INPUT_SIZES]
export type InputVariants = (typeof INPUT_VARIANTS)[keyof typeof INPUT_VARIANTS]
export type InputPositions = (typeof INPUT_POSITION)[keyof typeof INPUT_POSITION]
export type InputValidtype = (typeof INPUT_VALIDTYPE)[keyof typeof INPUT_VALIDTYPE]
export type InputValidstyle = (typeof INPUT_VALIDSTYLE)[keyof typeof INPUT_VALIDSTYLE]
