// import { defineStore } from 'pinia'

// export const authStore = defineStore({
//   id: 'authStore',
//   state: () => ({
//     // 상태 변수들 초기화
//     accessToken: '',
//     refreshToken: ''
//   }),
//   actions: {
//     setAccessToken(token: string) {
//       this.accessToken = token
//       console.log('store accessToken :: ', this.accessToken)
//     },
//     setRefreshToken(token: string) {
//       this.refreshToken = token
//       console.log('store refreshToken :: ', this.refreshToken)
//     }
//   }
// })
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { jwtVerify, SignJWT, decodeJwt } from 'jose'
import { useRouter } from 'vue-router'
import useAxios from '@/core/plugins/AxiosApi'

const { $axios } = useAxios()

export interface AuthState {
  user_id: string | null
  user_email: string | null
  accessToken: string | null
  refreshToken: string | null

  organizationSlug: string | null
  roles: Array<string> | null
  issuedAt: Date
  expirationTime: Date
}

// const jwt = require('jsonwebtoken')
const ACCESSTOKEN = 'accessToken' as string
const REFRESHTOKEN = 'refreshToken' as string
const router = useRouter()

export const useAuthStore = defineStore('authStore', () => {
  const authState = ref<AuthState>({} as AuthState)
  // 추가 필요 (accessToken, refreshToken토큰 없을 때)
  // const isAuthenticated = ref(!!JwtService.getToken());

  function setAccessToken(token: string) {
    authState.value.accessToken = token
    window.sessionStorage.setItem(ACCESSTOKEN, token)
    // console.log('VITE_APP_SECRET_KEY :: ', import.meta.env.VITE_APP_SECRET_KEY)
    // console.log('token :: ', authState.value.accessToken)
    // const a = createToken('keshion@gsretail.com')
    // console.log('만든 토큰 :: ', a)
    if (token != '') {
      decodeToken(token)
    }
  }

  function setRefreshToken(token: string) {
    authState.value.refreshToken = token
    window.sessionStorage.setItem(REFRESHTOKEN, token)
  }

  function getAccessToken() {
    if (authState.value.accessToken == null || '') {
      authState.value.accessToken = window.sessionStorage.getItem(ACCESSTOKEN)
      if (authState.value.accessToken != null) {
        decodeToken(authState.value.accessToken)
      }
    }
    return authState.value.accessToken
  }

  function getRefreshToken() {
    if (authState.value.refreshToken == null || '') {
      authState.value.refreshToken = window.sessionStorage.getItem(REFRESHTOKEN)
    }
    return authState.value.refreshToken
  }

  function resetAccessToken() {
    if (authState.value.accessToken == null || authState.value.accessToken == '') {
      if (
        window.sessionStorage.getItem(ACCESSTOKEN) == null ||
        window.sessionStorage.getItem(ACCESSTOKEN) == ''
      ) {
        router.push({ name: 'login' })
      } else {
        authState.value.accessToken = window.sessionStorage.getItem(ACCESSTOKEN)
        if (authState.value.accessToken != null) {
          decodeToken(authState.value.accessToken)
        }
      }
    }
  }

  function resetRefreshToken() {
    if (authState.value.refreshToken == null || authState.value.refreshToken == '') {
      if (
        window.sessionStorage.getItem(REFRESHTOKEN) == null ||
        window.sessionStorage.getItem(REFRESHTOKEN) == ''
      ) {
        router.push({ name: 'login' })
      } else {
        authState.value.refreshToken = window.sessionStorage.getItem(REFRESHTOKEN)
      }
    }
  }

  async function decodeToken(token: string) {
    const secretKey = new TextEncoder().encode(import.meta.env.VITE_APP_SECRET_KEY)

    try {
      // console.log('secretKey ::  ', secretKey)

      const decodedHeader = decodeJwt(token)
      // console.log('Decoded Header:', decodedHeader)

      // user_id 클레임 추출
      if (
        typeof decodedHeader === 'object' &&
        decodedHeader !== null &&
        'user_id' in decodedHeader
      ) {
        authState.value.user_id = decodedHeader.user_id as string
        // 우선 user-id만 저장
        window.sessionStorage.setItem('USERID', authState.value.user_id)
        // console.log('User ID:', authState.value.user_id)

        authState.value.user_email = decodedHeader.user_email as string
        window.sessionStorage.setItem('USEREMAIL', authState.value.user_email)

        authState.value.organizationSlug = decodedHeader.organizationSlug as string
        // console.log('organizationSlug:', authState.value.organizationSlug)

        authState.value.roles = decodedHeader.roles as Array<string>
        // console.log('roles:', authState.value.roles)

        authState.value.issuedAt = new Date(decodedHeader.iat! * 1000)
        // console.log('issuedAt:', authState.value.issuedAt)

        authState.value.expirationTime = new Date(decodedHeader.exp! * 1000)
        // console.log('expirationTime:', authState.value.expirationTime)
      }

      // // 토큰의 발행 시간 (iat)
      // const issuedAt = new Date(decodedHeader.iat! * 1000)
      // console.log('Issued At:', issuedAt)

      // // 토큰의 만료 시간 (exp)
      // const expirationTime = new Date(decodedHeader.exp! * 1000)
      // console.log('Expiration Time:', expirationTime)

      // // 현재 시간과 비교
      // const currentTime = new Date()
      // if (expirationTime < currentTime) {
      //   console.log('Token is expired')
      // } else {
      //   console.log('Token is valid')
      // }
    } catch (err: any) {
      console.error('Token is expired or invalid:', err)
      if (err.name === 'TokenExpiredError') {
        console.error('Token is expired:', err)
      } else {
        console.error('Invalid token:', err)
      }
    }
  }

  async function checkTokenDate() {
    const currentTime = new Date()
    if (authState.value.expirationTime == null) {
      resetAccessToken()
      resetRefreshToken()
      // console.log('RESET TOKEN!!')
    }
    // console.log('time :: ', authState.value.expirationTime, currentTime)
    // console.log('값 :: ', authState.value.expirationTime < currentTime)
    if (authState.value.expirationTime < currentTime) {
      // console.log('Token is expired')
      await destroyToken()
    } else {
      // console.log('Token is valid')
    }
  }

  async function destroyToken() {
    authState.value.accessToken = ''
    window.sessionStorage.setItem(ACCESSTOKEN, '')
    await $axios
      .post('/api/gsretail/auth/rfresh', authState.value.refreshToken)
      .then((response) => {
        // console.log('/api/gsretail/auth/rfresh :: ', response)
        // organizationsData.data.data = response.data
        // console.log('organizationsData :: ', organizationsData)
        const accessToken: string = response.data.accessToken
        const refreshToken: string = response.data.refreshToken
        setAccessToken(accessToken)
        setRefreshToken(refreshToken)
      })
      .catch((error) => {
        console.error(error)
      })
    // router.push({ name: 'login' })
  }

  // const jwt = require('jsonwebtoken')

  // async function decodeToken(token: string) {
  //   const secretKey = new TextEncoder().encode(import.meta.env.VITE_APP_SECRET_KEY)

  //   try {
  //     console.log('secretKey ::  ', secretKey)

  //     const decodedHeader = decodeJwt(token)
  //     console.log('Decoded Header:', decodedHeader)

  //     // 'alg' 값 확인
  //     // const algorithm = decodedHeader.header.alg
  //     // console.log('Algorithm:', algorithm)

  //     // 토큰 검증 및 파싱
  //     const { payload } = await jwtVerify(token, secretKey, {
  //       algorithms: ['HS256', 'HS384', 'HS512']
  //     })
  //     console.log('Decoded JWT:', payload)

  //     // user_id 클레임 추출
  //     const user_id = payload.user_id
  //     console.log('User ID:', user_id)

  //     // 토큰의 발행 시간 (iat)
  //     const issuedAt = new Date(payload.iat! * 1000)
  //     console.log('Issued At:', issuedAt)

  //     // 토큰의 만료 시간 (exp)
  //     const expirationTime = new Date(payload.exp! * 1000)
  //     console.log('Expiration Time:', expirationTime)

  //     // 현재 시간과 비교
  //     const currentTime = new Date()
  //     if (expirationTime < currentTime) {
  //       console.log('Token is expired')
  //     } else {
  //       console.log('Token is valid')
  //     }
  //   } catch (err: any) {
  //     console.error('Token is expired or invalid:', err)
  //     if (err.name === 'TokenExpiredError') {
  //       console.error('Token is expired:', err)
  //     } else {
  //       console.error('Invalid token:', err)
  //     }
  //   }
  // }

  // async function createToken(user_id: string) {
  //   const secretKey = new TextEncoder().encode(import.meta.env.VITE_APP_SECRET_KEY)
  //   const jwt = await new SignJWT({ user_id })
  //     .setProtectedHeader({ alg: 'HS384' })
  //     .setIssuedAt()
  //     .setExpirationTime('2h')
  //     .sign(secretKey)

  //   return jwt
  // }

  return {
    authState,
    setAccessToken,
    setRefreshToken,
    getAccessToken,
    getRefreshToken,
    resetAccessToken,
    resetRefreshToken,
    decodeToken,
    checkTokenDate
  }
})

// export const useAuthStore = defineStore("authStore", () => {
//   const accessToken = ref<AuthState>.accessToken;
//   function setAccessToken(this: AuthState, token: string) {
//     this.accessToken = token
//     window.sessionStorage.setItem(ACCESSTOKEN, token)
//     console.log('store에서 accessToken ::: ', this.accessToken)
//     console.log('localStorage에서 accessToken ::: ', getAccessToken())
//   }
//   function  setRefreshToken(this: AuthState, token: string) {
//     this.refreshToken = token
//     window.sessionStorage.setItem(REFRESHTOKEN, token)
//     console.log('store에서 refreshToken ::: ', this.refreshToken)
//     console.log('sessionStorage refreshToken ::: ', this.refreshToken)
//   }
//   function  getAccessToken() {
//     return this.accessToken == null || ''
//       ? window.sessionStorage.getItem(ACCESSTOKEN)
//       : this.accessToken
//   }
// function  getRefreshToken() {
//     return this.refreshToken == null || ''
//       ? window.sessionStorage.getItem(REFRESHTOKEN)
//       : this.refreshToken
//   }

// })
