import { ref } from 'vue'

// 공유할 변수에 대한 타입을 정의합니다.
const openProfileMenu = ref(false)
const selectedOrganizationName = ref<string | undefined>(undefined)
const selectedOrganizationId = ref<string | undefined>(undefined)

// composable 함수를 정의하고 export
export function useHeaderState() {
  return { openProfileMenu, selectedOrganizationName, selectedOrganizationId }
}
