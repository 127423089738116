<template>
  <v-dialog persistent v-model="isDialogOpen" class="loading-popup-area">
    <v-card>
      <div>
        <lottie-player
          :src="loadingAnimation"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
        <p>잠시만 기다려주세요.</p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import loadingAnimation from '@/assets/json/loading.json'
const isDialogOpen = ref(true)
</script>
<script lang="ts">
export default {}
</script>
