// import type { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios'
// import axios from 'axios'
// import VueAxios from 'vue-axios'
// import type { App, Plugin } from 'vue'
// import { useAuthStore, type AuthState } from '@/stores/auth'

// export class AxiosApi {
//   public readonly axiosInstance: AxiosInstance
//   public static vueInstance: App

//   public static init(app: App<Element>) {
//     AxiosApi.vueInstance = app
//     AxiosApi.vueInstance.use(VueAxios, axios)
//     // ApiService.vueInstance.axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL
//   }

//   public static setHeader(token: String | null): void {
//     // AxiosApi.vueInstance.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
//     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
//   }

//   public static get(
//     token: String | null,
//     resource: string,
//     slug = '' as string
//   ): Promise<AxiosResponse> {
//     const axiosInstance = axios.create({
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     })
//     // console.log(
//     //   'get :: ',
//     //   'resource :: ',
//     //   resource,
//     //   'slug :: ',
//     //   slug,
//     //   'import.meta.env::: ',
//     //   import.meta.env
//     // )
//     return axiosInstance.get(`${resource}/${slug}`)
//   }

//   //   constructor(config: AxiosRequestConfig) {
//   constructor() {
//     this.axiosInstance = axios.create({})
//   }
// }
// const axiosInstance = axios.create({
//   headers: {
//     Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`
//   }
// })

// export { axiosInstance }

import { reactive } from 'vue'
import axios, { type AxiosInstance } from 'axios'
import { useAuthStore } from '@/stores/auth'
import env from '@/core/env/env'

const envInstance = new env()

const axiosInstance: AxiosInstance = axios.create({
  baseURL: envInstance.url == 'http://10.250.40.6:8089' ? '/api' : '',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
    // Authorization: `Bearer ${window.sessionStorage.getItem('accessToken')}`
  }
})

export default function useAxios() {
  const state = reactive({
    $axios: axiosInstance
  })

  return state
}

axiosInstance.interceptors.request.use(
  async (config) => {
    // Authorization 헤더를 동적으로 설정
    const authStore = useAuthStore()
    // await authStore.checkTokenDate()

    const token = window.sessionStorage.getItem('accessToken')
    // console.log('token :: ', token)
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    // config.headers.Authorization = `Basic R3NSZXRhaWxUQjpHc1JldGFpbFRCX3B3IUA`
    // config.headers.SubKey = `Basic R3NSZXRhaWxUQjpHc1JldGFpbFRCX3B3IUA`
    // config.headers['Content-Type'] = 'application/json; charset=utf-8'
    return config
  },
  (error) => {
    // 요청 오류 처리
    return Promise.reject(error)
  }
)
