<template>
  <v-btn
    :class="computedClassName()"
    v-bind="{
      prependIcon,
      appendIcon,
      stacked,
      ...(icon ? { icon } : {})
    }"
  >
    <template v-if="text != ''">
      {{ translate(text) }}
    </template>
  </v-btn>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { defineProps } from 'vue'
import { BUTTON_VARIANTS, BUTTON_SIZES, BUTTON_COLORS } from '@/constants/buttonConstants'
import type { ButtonSize, ButtonColor, ButtonVariant } from '@/constants/buttonConstants'
const { t, te } = useI18n()

const translate = (text: string) => {
  if (te(text)) {
    return t(text)
  } else {
    return text
  }
}

interface Props {
  text?: string
  className?: string | string[]
  variant: ButtonVariant
  size: ButtonSize
  color: ButtonColor
  icon?: string
  appendIcon?: string
  prependIcon?: string
  stacked?: boolean
  // outline?: string 테두리 클래스
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  variant: BUTTON_VARIANTS.CONTAINED,
  size: BUTTON_SIZES.MEDIUM,
  color: BUTTON_COLORS.PRIMARY
})

const computedClassName = () => {
  const baseClassNames = Array.isArray(props.className) ? props.className : [props.className]

  return [...baseClassNames, props.variant, props.color, props.size]
}
</script>
<script lang="ts">
export default {}
</script>
