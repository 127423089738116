<template>
  <GSLoading v-if="showLoading"></GSLoading>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import GSLoading from '@/components/ui/GSLoading.vue'
import { defineProps } from 'vue'

const props = defineProps<{
  access_token?: string
  refresh_token?: string
}>()

const store = useAuthStore()
const route = useRoute()
const router = useRouter()
const code = ref<string | null>('')
const showLoading = ref<boolean>(false)

const getCookieValue = (cookieName: string) => {
  var cookieString = document.cookie
  var cookies = cookieString.split(';')

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim()
    var cookieParts = cookie.split('=')
    if (cookieParts[0] === cookieName) {
      return cookieParts[1]
    }
  }
  return null
}

const parseQueryString = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get('code')
}

const fetchToken = (code: string | null) => {
  axiosInstance
    .get(`/api/gsretail/auth/callback?code=${code}`)
    .then((response) => {
      const accessToken: string = response.data.accessToken
      const refreshToken: string = response.data.refreshToken
      store.setAccessToken(accessToken)
      store.setRefreshToken(refreshToken)
      router.push({ name: 'sendingstatistics' })
    })
    .catch((error) => {
      console.error('Error auth:', error)
    })
  return
}

const axiosInstance = axios.create({
  baseURL: '',
  withCredentials: true
})

const getToken = () => {
  // const currentUrl = window.location.href

  // // URLSearchParams를 사용하여 쿼리 파라미터 추출
  // const url = new URL(currentUrl)
  // const params = new URLSearchParams(url.search)

  // 특정 키 추출
  // const accessToken = params.get('access_token')
  // const refreshToken = params.get('refresh_token')
  const accessToken = route.query.access_token as string
  const refreshToken = route.query.refresh_token as string

  // 콘솔로 출력하거나 다른 로직에 사용
  console.log('Access Token:', accessToken)
  console.log('Refresh Token:', refreshToken)

  if (accessToken && refreshToken) {
    store.setAccessToken(accessToken)
    store.setRefreshToken(refreshToken)
  } else {
    console.log('토큰 안넘어옴')
  }
}

onMounted(() => {
  setTimeout(() => {
    showLoading.value = true
  }, 200)
  var sendyCookieValue = getCookieValue('sendyCookie')

  console.log('sendyCookieValue ::: ', sendyCookieValue)
  getToken()
  if (sendyCookieValue === null) {
    console.log('local dev 제외')
    router.push({ name: 'sendingstatistics' })
  } else {
    if (sendyCookieValue == 'dev') {
      console.log('DEV환경')
      router.push({ name: 'sendingstatistics' })
    } else {
      const currentUrl = window.location.href
      var newIpAddress = 'localhost:8089'
      var regex = /(\d+\.\d+\.\d+\.\d+)/
      var match = regex.exec(currentUrl)
      if (match) {
        console.log('LOCAL로 redirect')
        // router.push({ name: 'sendingstatistics' })
        // fetchToken(code.value)
        var oldIpAddress = match[0]
        var newUrl = currentUrl.replace(oldIpAddress, newIpAddress)
        window.location.href = newUrl
      } else {
        console.log('LOCAL환경')
        router.push({ name: 'sendingstatistics' })
        // code.value = parseQueryString()
        // console.log('LOCAL도 아니고 DEV도 아님')
        // fetchToken(code.value)
      }
    }
  }
  // if (sendyCookieValue === null) {
  //   code.value = parseQueryString()
  //   // fetchToken(code.value)
  // } else {
  //   if (sendyCookieValue == 'dev') {
  //     code.value = parseQueryString()
  //     // fetchToken(code.value)
  //   } else {
  //     const currentUrl = window.location.href
  //     var newIpAddress = 'localhost:8089'
  //     var regex = /(\d+\.\d+\.\d+\.\d+)/
  //     var match = regex.exec(currentUrl)
  //     if (match) {
  //       // fetchToken(code.value)
  //       var oldIpAddress = match[0]
  //       var newUrl = currentUrl.replace(oldIpAddress, newIpAddress)
  //       window.location.href = newUrl
  //     } else {
  //       code.value = parseQueryString()
  //       // fetchToken(code.value)
  //     }
  //   }
  // }
})
</script>
