import env from '@/core/env/env'

const envInstance = new env()

export const commonResponse = (response: any) => {
  // console.log('Response :: ', response)
  const status = response.status
  if (status == '200') {
    return response.data
  } else {
    switch (status) {
      case 202:
        break
      case 400:
        break
      case 401:
        break
      case 403:
        break
      case 404:
        break
      case 405:
        break
      case 409:
        break
      case 419:
        break
      case 422:
        break
      case 423:
        break
      case 500:
        break
      case 503:
        break
      case 512:
        break
    }
  }
}

export const commonError = (error: any) => {
  /* 공통 에러처리 */
  const returnObj = {
    redirectURL: '',
    message: '',
    icon: '',
    callBack: ''
  }
  console.log('ERROR ::: ', error)
  if (error.response.status === 401) {
    window.sessionStorage.removeItem('USERID')
    returnObj.message = '인증 유효기간이 만료되었습니다. 다시 로그인해 주세요.'
    returnObj.redirectURL = envInstance.url + '/gsretail/auth/login'
    return returnObj
  } else {
    returnObj.message =
      '요청하신 처리 중 오류가 발생했습니다. 네트워크 상태를 확인한 후 다시 시도해 주세요. 문제가 지속될 경우, 잠시 후 다시 시도하거나 관리자(sendy@gsretail.com)에게 문의해 주시기 바랍니다.'
    return returnObj
  }
}
