import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import i18n from '@/core/plugins/i18n'
// import axios from 'axios'
import vuetify from '@/core/plugins/vuetify' // 추가
import '@/core/plugins/prismjs'

import '@mdi/font/css/materialdesignicons.css'
import '@lottiefiles/lottie-player'
// import { Tooltip } from 'bootstrap'

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */

// import ElementPlus from 'element-plus'

// import axiosPlugin from '@/core/plugins/AxiosApi'

//imports for app initialization
// import ApiService from '@/core/services/ApiService'
// import { initApexCharts } from '@/core/plugins/apexcharts'
// import { initInlineSvg } from '@/core/plugins/inline-svg'
// import { initVeeValidate } from '@/core/plugins/vee-validate'
// import { initKtIcon } from '@/core/plugins/keenthemes'

// import 'vuetify/styles'

// import SendyComponent from 'sendy-component-lib'

// import api from '@/utils/api'
function isMobile(): boolean {
  return /android|iphone|ipad|mobile/i.test(navigator.userAgent)
}

if (isMobile()) {
  import('@/styles/mobilestyle.scss').catch((error) => {
    console.error('모바일 CSS 로드 실패:', error)
  })
} else {
  import('@/styles/style.scss').catch((error) => {
    console.error('데스크탑 CSS 로드 실패:', error)
  })
}

const app = createApp(App)
const pinia = createPinia()
// app.use(createPinia())

app.use(pinia)
app.use(router)
app.use(vuetify)
app.use(i18n)
// app.use(ElementPlus)
// app.use(SendyComponent)

// 주석
// const axiosInstance = axios.create({
//   baseURL: 'https://api.example.com',
//   timeout: 5000,
//   headers: {
//     'Content-Type': 'application/json',
//     Authorization: 'Bearer your-token-here'
//   }
// })
// app.config.globalProperties.$axios = axiosInstance

// app.use(axiosPlugin)
// const axiosApi = new AxiosApi()
// app.provide('axiosApi', axiosApi)

// function myGlobalFunction() {
//   console.log('This is a global function')
// }

// app.use(myGlobalFunction)

// app.mixin({
//   methods: {
//     $myGlobalFunction: myGlobalFunction
//   }
// })

// app.config.globalProperties.$axios = ApiService
// const axiosApi = new ApiService()
// app.config.globalProperties.$axiosApi = axiosApi
// app.config.globalProperties.$apiUrl = import.meta.env.VITE_APP_API_URL

// app.provide('api', api)

// ApiService.init(app)
// initApexCharts(app)
// initInlineSvg(app)
// initKtIcon(app)
// initVeeValidate()

// const axios = new ApiService()
// app.provide('axios', axios)

// app.directive('tooltip', (el) => {
//   new Tooltip(el)
// })
document.title = import.meta.env.VITE_APP_TITLE || 'GS SENDY'

app.mount('#app')
