<template>
  <div class="mobile-guide">
    <div class="mobile-guide-header">
      <img src="/src/assets/images/gs_sendy_mobile.png" alt="" />
    </div>
    <div class="mobile-guide-main">
      <div>
        <p class="title">PC에서 접속해 주세요</p>
        <img src="/src/assets/images/mobile_guide_img.jpg" alt="" />
        <p class="text">
          이 사이트는 모바일 버전을 제공하지 않습니다.<br />
          PC로 접속해 주세요.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
body {
  width: 1000px !important;
}
</style>
