export const RADIO_COLORS = {
  PRIMARY: 'gsr-radio-primary',
  SECONDARY: 'gsr-radio-secondary',
  INHERIT: 'gsr-radio-inherit'
} as const

export const RADIO_SIZES = {
  SMALL: 'gsr-radio-small',
  MEDIUM: 'gsr-radio-medium',
  LARGE: 'gsr-radio-large'
} as const

// 타입 정의
export type RadioColors = (typeof RADIO_COLORS)[keyof typeof RADIO_COLORS]
export type RadioSizes = (typeof RADIO_SIZES)[keyof typeof RADIO_SIZES]
