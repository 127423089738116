<template>
  <div class="loading-area">
    <div></div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue'
import env from '@/core/env/env'

const envInstance = new env()
const setCookie = (name: string, value: string, days: number) => {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

onMounted(() => {
  // if (envInstance.mode == 'devlocal') {
  //   setCookie('sendyCookie', 'local', 30)
  // }
  // if (envInstance.mode == 'development') {
  //   setCookie('sendyCookie', 'dev', 30)
  // }

  const params = new URLSearchParams(window.location.search)
  const referrer: string | null = params.get('referrer')
  if (envInstance.mode == 'devlocal') {
    window.location.href = `http://10.250.40.6/login?referrer=localhost`
    return
  } else {
    console.log('시작 체크 ', window.location.href.startsWith('http://10.250.40.6/login'))
    if (window.location.href.startsWith('http://10.250.40.6/login')) {
      if (referrer !== null) {
        console.log('localhost에서 옴')
        setCookie('sendyCookie', 'local', 30)
        window.location.href = envInstance.url + '/api/auth/gsretail/login'
        return
      } else {
        console.log('개발에서 옴')
        setCookie('sendyCookie', 'dev', 30)
        window.location.href = envInstance.url + '/api/auth/gsretail/login'
        return
      }
    } else {
      console.log()
      window.location.href = envInstance.url + '/api/auth/gsretail/login'
      return
    }
  }
  // if (referrer !== null) {
  //   console.log(decodeURIComponent(referrer)) // 이전 URL 출력
  //   if (referrer == 'local') {
  //     console.log('localhost에서 옴')
  //     setCookie('sendyCookie', 'local', 30)
  //     window.location.href = 'http://10.250.40.6:8089/api/auth/gsretail/login'
  //   } else if (referrer == 'dev') {
  //     console.log('개발에서 옴')
  //     setCookie('sendyCookie', 'dev', 30)
  //     window.location.href = 'http://10.250.40.6:8089/api/auth/gsretail/login'
  //   }
  // } else {
  //   return
  //   // window.location.href = `http://10.250.40.6/login?referrer=localhost`
  // }

  // window.location.href = envInstance.url + '/api/auth/gsretail/login'

  // window.location.href = envInstance.url + '/gsretail/auth/login'
  // window.location.href = 'http://10.250.40.6:8089/api/auth/gsretail/login'
  // window.location.href = 'http://10.250.40.6/login'
  // window.location.href = `http://10.250.40.6/login?referrer=localhost`
})
</script>
