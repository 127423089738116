export const SELECTS_VARIANTS = {
  OUTLINED: 'gsr-selects-outlined',
  STANDARD: 'gsr-selects-standard'
} as const

export const SELECTS_SIZES = {
  SMALL: 'gsr-selects-small',
  MEDIUM: 'gsr-selects-medium',
  LARGE: 'gsr-selects-large'
} as const

// 타입 정의
export type SelectsSizes = (typeof SELECTS_SIZES)[keyof typeof SELECTS_SIZES]
export type SelectsVariants = (typeof SELECTS_VARIANTS)[keyof typeof SELECTS_VARIANTS]
