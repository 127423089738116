<template>
  <div class="sending-statistics">
    <strong class="contents-title"> {{ organizationName }} 발송통계</strong>
    <div class="data-table-area">
      <v-tabs height="54" v-model="tab">
        <v-tab class="sms">
          {{ translate('SMS / LMS / MMS') }}
        </v-tab>
        <v-tab class="kakaotalk">
          {{ translate('KakaoTalk') }}
        </v-tab>
      </v-tabs>
      <v-tabs-window v-model="tab">
        <slmms-tab></slmms-tab>
        <kakaotalk-tab></kakaotalk-tab>
      </v-tabs-window>
    </div>
  </div>
</template>

<script setup lang="ts">
import SlmmsTab from './SlmmsTab.vue'
import KakaotalkTab from './KakaotalkTab.vue'
import emitter from '@/core/plugins/mitt'
// S: 다국어
import { useI18n } from 'vue-i18n'

const { t, te } = useI18n()
const i18n = useI18n()

i18n.locale.value = 'kr'

// @ts-ignore
const translate = (text: string) => {
  if (te(text)) {
    return t(text)
  } else {
    return text
  }
}
// E: 다국어

import { ref, onMounted } from 'vue'
// import Organization from './management/Organization.vue';

const organizationName = ref('')

const tab = ref([0])

onMounted(() => {
  emitter.on('updateOrganization', (value: string | null) => {
    if (value != null) {
      organizationName.value = value
    } else {
      organizationName.value = ''
    }
  })
})
</script>
