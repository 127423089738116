export const BUTTON_VARIANTS = {
  CONTAINED: 'gsr-btn-contained',
  OUTLINED: 'gsr-btn-outlined',
  TEXT: 'gsr-btn-text'
} as const

export const BUTTON_SIZES = {
  TINY: 'gsr-btn-tiny',
  SMALL: 'gsr-btn-small',
  MEDIUM: 'gsr-btn-medium',
  LARGE: 'gsr-btn-large'
} as const

export const BUTTON_COLORS = {
  PRIMARY: 'gsr-btn-primary',
  SECONDARY: 'gsr-btn-secondary',
  INHERIT: 'gsr-btn-inherit'
} as const

// 타입 정의
export type ButtonVariant = (typeof BUTTON_VARIANTS)[keyof typeof BUTTON_VARIANTS]
export type ButtonSize = (typeof BUTTON_SIZES)[keyof typeof BUTTON_SIZES]
export type ButtonColor = (typeof BUTTON_COLORS)[keyof typeof BUTTON_COLORS]
