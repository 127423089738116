import { createRouter, createWebHistory } from 'vue-router'

// import CommonRouter from './modules/common/common'
import HomeRouter from './modules/home'
// import WorkspaceRouter from './modules/workspace'
// import AudienceRouter from './modules/audience'
// import TemplatesRouter from './modules/templates'
// import ManagementRouter from './modules/management'
// import SettingRouter from './modules/setting'
// import ProfileRouter from './modules/profile'
//
// import AnalyticesRouter from './modules/workspace/analytices'
// import AutomationsRouter from './modules/workspace/automations'
// import DashboardRouter from './modules/workspace/dashboard'
// import SendingRouter from './modules/workspace/sending'
// import TemplateRouter from './modules/workspace/template'

import LoginView from '@/views/auth/LoginView.vue'
import LoginBack from '@/views/auth/LoginBack.vue'
import System from '@/views/auth/SendSystem.vue'
import MobileDevice from '@/views/auth/MobileDevice.vue'

import Error from '@/views/auth/ErrorView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // ...CommonRouter,
    ...HomeRouter,
    // ...WorkspaceRouter,
    // ...AudienceRouter,
    // ...TemplatesRouter,
    // ...ManagementRouter,
    // ...SettingRouter,
    // ...ProfileRouter,
    //
    // ...AnalyticesRouter,
    // ...AutomationsRouter,
    // ...DashboardRouter,
    // ...SendingRouter,
    // ...TemplateRouter,
    {
      path: '/',
      redirect: '/login'
      // redirect: (to) => {
      //   console.log('MODE ::: ', import.meta.env.VITE_APP_NODE_ENV)
      //   if (import.meta.env.VITE_APP_NODE_ENV === 'local') {
      //     // 개발 환경에서는 쿼리 파라미터 추가
      //     return {
      //       query: { referrer: 'local' },
      //       path: '/login'
      //     }
      //   } else if (import.meta.env.VITE_APP_NODE_ENV === 'dev') {
      //     return {
      //       query: { referrer: 'dev' },
      //       path: '/login'
      //     }
      //   } else {
      //     return ''
      //   }
      // //다른 환경에서는 파라미터 없이 리다이렉트
      // }
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginView
    },
    {
      path: '/auth/callback',
      name: 'devloginBack',
      component: LoginBack
    },
    {
      path: '/console/auth/logincallback',
      name: 'loginBack',
      component: LoginBack,
      props: (route) => ({
        accessToken: route.query.access_token,
        refreshToken: route.query.refresh_token
      })
    },
    // {
    //   path: '/console/auth/logincallback',
    //   name: 'loginBack',
    //   component: LoginBack
    // },
    {
      path: '/system',
      name: 'System',
      component: System
    },
    {
      path: '/checkdevice',
      name: 'checkdevice',
      component: MobileDevice
    },
    {
      path: '/:catchAll(.*)',
      name: 'error',
      component: Error
    }
  ]
})

const detectMobileDevice = (): boolean => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera
  return /android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
}

router.beforeEach((to, from, next) => {
  const isMobile = detectMobileDevice()

  if (isMobile && to.name !== 'checkdevice') {
    next({ name: 'checkdevice' })
  }

  if (
    to.name !== 'Login' &&
    to.name !== 'loginBack' &&
    to.name !== 'devloginBack' &&
    to.name !== 'System' &&
    to.name !== 'checkdevice'
  ) {
    if (
      window.sessionStorage.getItem('USERID') == null ||
      window.sessionStorage.getItem('USERID') == ''
    ) {
      next({ name: 'Login' })
    }
  }

  const isAuthenticated = !!window.sessionStorage.getItem('accessToken') // 로그인 상태 확인 (accessToken이 존재하는지)

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 로그인이 필요한 페이지인 경우
    if (!isAuthenticated) {
      // 로그인되어 있지 않으면 로그인 페이지로 리다이렉트
      // 20241004
      // next({ name: 'Login' })
      next()
    } else {
      // 로그인되어 있으면 그대로 진행
      next()
    }
  } else {
    // 로그인이 필요하지 않은 페이지는 그대로 진행
    next()
  }
})

// router.beforeEach((to, from, next) => {
//   // 메타 데이터에서 requiresMenu 값을 확인합니다.
//   const requiresMenu = to.matched.some((record) => record.meta.requiresMenu)

//   console.log('requiresMenu :: ', requiresMenu)
//   // requiresMenu가 true이면 정상적으로 진행합니다.
//   if (requiresMenu) {
//     next()
//   } else {
//     // 메뉴에 없는 경우 접근을 차단합니다.
//     next(false) // 또는 다른 경로로 리디렉션할 수도 있습니다.
//   }
// })

export default router
