<template>
  <v-tabs-window-item>
    <v-container>
      <v-row>
        <v-col>
          <!-- 검색 -->
          <div class="data-table-contents search">
            <div class="search-option-area">
              <!-- 검색 - 기간 -->
              <div class="option-date">
                <div class="label-box">
                  <span class="label essential">{{ translate('기간') }}</span>
                </div>
                <v-btn-toggle v-model="selectedPeriod" mandatory class="date-range-btn-toggle">
                  <v-btn
                    v-for="(item, index) in datePeriod"
                    :key="item.value"
                    :value="item.value"
                    :class="{ active: activeIndex === index }"
                    @click="selectedPeriodBtn(item.value, index)"
                    :disabled="isDisabled"
                  >
                    {{ item.text }}
                  </v-btn>
                </v-btn-toggle>
                <v-menu
                  v-model="openDatePicker"
                  class="gsr-select-date"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ props }">
                    <v-form class="gsr-select-date-form" v-bind="props">
                      <div>
                        <div class="gsr-select-date-input">
                          <v-text-field
                            placeholder="YYYY-MM-DD"
                            @input="(e) => formatDateInput(e, 'start')"
                            v-model="searchStartDate"
                            maxlength="10"
                            @click.stop="onClickDateInput"
                          ></v-text-field>
                          ~
                          <v-text-field
                            placeholder="YYYY-MM-DD"
                            @input="(e) => formatDateInput(e, 'end')"
                            v-model="searchEndDate"
                            maxlength="10"
                            @click.stop="onClickDateInput"
                          ></v-text-field>
                        </div>
                      </div>
                      <div
                        :class="
                          isDateSelected
                            ? 'gsr-select-date-clear-btn on'
                            : 'gsr-select-date-clear-btn off'
                        "
                      >
                        <v-btn @click="clearDate()" @click.stop></v-btn>
                      </div>
                    </v-form>
                  </template>
                  <v-card class="gsr-select-date-layer">
                    <v-row no-gutters>
                      <v-col>
                        <v-date-picker
                          hide-header
                          show-adjacent-months
                          v-model="selectedStartDate"
                          :max="selectedEndDate"
                        >
                        </v-date-picker>
                      </v-col>
                      <v-col>
                        <v-date-picker
                          hide-header
                          show-adjacent-months
                          v-model="selectedEndDate"
                          :min="selectedStartDate"
                        >
                        </v-date-picker>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="gsr-select-date-btn-area">
                      <GSButton
                        :class="'select-date-today-btn'"
                        :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                        :size="Constants.BUTTON_SIZES.SMALL"
                        :color="Constants.BUTTON_COLORS.SECONDARY"
                        text="오늘"
                        width="48"
                        @click="setToday()"
                      ></GSButton>
                      <v-list class="gsr-select-date-btn-list">
                        <v-list-item>
                          <GSButton
                            :class="'select-date-reset-btn'"
                            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                            :size="Constants.BUTTON_SIZES.SMALL"
                            :color="Constants.BUTTON_COLORS.SECONDARY"
                            width="32"
                            @click="resetDate()"
                          ></GSButton>
                        </v-list-item>
                        <v-list-item>
                          <GSButton
                            :class="'select-date-cancle-btn'"
                            :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                            :size="Constants.BUTTON_SIZES.SMALL"
                            :color="Constants.BUTTON_COLORS.INHERIT"
                            text="닫기"
                            width="48"
                            @click="closeDate()"
                          ></GSButton>
                        </v-list-item>
                        <v-list-item>
                          <GSButton
                            :class="'select-date-confirm-btn'"
                            :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                            :size="Constants.BUTTON_SIZES.SMALL"
                            :color="Constants.BUTTON_COLORS.PRIMARY"
                            text="선택완료"
                            width="69"
                            @click="cofirmDate()"
                          ></GSButton>
                        </v-list-item>
                      </v-list>
                    </v-row>
                  </v-card>
                </v-menu>
              </div>
              <!-- 검색 - 채널 -->
              <div class="option-channel">
                <div class="label-box">
                  <span class="label">{{ translate('채널') }}</span>
                </div>
                <v-select
                  class="gsr-selects-outlined gsr-selects-small"
                  ref="refChannel"
                  v-model:menu="isChannelOpen"
                  v-model="selectedChannel"
                  :items="channelList"
                  @click="clickChannel"
                >
                </v-select>
              </div>
              <!-- 검색 - workspace -->
              <div class="option-workspace">
                <div class="label-box">
                  <span class="label">{{ translate('Workspace') }}</span>
                </div>
                <v-menu
                  class="gsr-select-autocomplete"
                  :close-on-content-click="false"
                  v-model="openSearchWorkspace"
                  @update:modelValue="handleMenuOpen"
                >
                  <template #activator="{ props }">
                    <v-btn v-bind="props" class="gsr-select-autocomplete-btn">
                      <span>{{ selectedWorkspace?.workspace_name }}</span>
                    </v-btn>
                  </template>
                  <v-card class="gsr-select-autocomplete-layer" v-click-outside="onClickOutside">
                    <span class="title">
                      {{ translate('Workspace 검색') }}
                    </span>
                    <v-autocomplete
                      v-if="showAutocomplete"
                      ref="refWorkspaceList"
                      v-model:menu="isAutocompleteVisible"
                      v-model="selectedWorkspace"
                      :clearable="true"
                      :items="workspaceList"
                      item-title="workspace_name"
                      item-value="workspace_id"
                      placeholder="검색어 입력"
                      no-data-text="검색결과가 없습니다."
                      return-object
                      :open-text="selectedWorkspace?.workspace_name"
                      @keydown="onKeydownWorkspace"
                    ></v-autocomplete>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <v-btn flat class="search-btn" @click="searchStatistics">{{ translate('조회') }}</v-btn>
            <v-dialog v-model="dialog" class="dialog-layer search-error-layer" persistent>
              <v-card class="gsr-popup">
                <div class="gsr-popup-icon">
                  <i></i>
                </div>
                <p class="gsr-popup-text">1년 이내로 설정하여 조회해 주세요.</p>
                <div class="gsr-popup-btn">
                  <v-btn ref="confirmBtn" class="confirm" @click="closeDialog">확인</v-btn>
                </div>
              </v-card>
            </v-dialog>
          </div>
          <!-- 사용현황 -->
          <div class="data-table-contents state">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('사용현황') }}
                </strong>
                <p class="contents-sub-title-detail" v-if="totalCostInfo !== null">
                  {{ totalInfoStartDate }} {{ ' ~ ' }} {{ totalInfoEndDate }} {{ ' 기준' }}
                </p>
              </div>
            </div>
            <v-list v-if="totalCostInfo !== null">
              <!-- 사용요금 -->
              <v-list-item>
                <div class="result-title">
                  <strong class="name charge"> 사용요금 </strong>
                  <v-btn class="tooltip-btn">
                    <v-tooltip
                      class="tooltip-layer"
                      activator="parent"
                      location="bottom start"
                      origin="auto"
                      scroll-strategy="reposition"
                    >
                      <div class="tooltip-area">
                        <strong class="tooltip-title">사용요금</strong>
                        <p class="tooltip-sub-title">
                          기간별 사용요금은 실제 청구금액과 동일하게 계산하여 제공됩니다.
                        </p>
                        <div class="tooltip-sub-area">
                          <ul class="tooltip-detail-list">
                            <li>
                              <p>사용요금 계산 방법 : <span>부가세 포함된 사용요금/1.1</span></p>
                              <p class="detail">소수점 반올림(5부터 올림)</p>
                            </li>
                            <li>
                              <p>
                                부가세 포함된 사용금액 :
                                <span
                                  >((성공 건수*채널 요금)+(대체 성공*대체 채널 요금))+((성공
                                  건수*채널 요금)+(대체 성공*대체 채널 요금))*10%)</span
                                >
                              </p>
                              <p class="detail">원단위 이하 절사</p>
                            </li>
                            <li>
                              <strong>e.g. LMS 기준</strong>
                              <p>성공 건수 = 376건</p>
                              <p>사용요금 = 376* 23.637= 8,887.512원</p>
                              <p>부가세 = 8,887.512 * 10% = 888.7512원</p>
                              <p>청구금액 (vat 포함) = 8,887.512+888.7512=9,776.2632원</p>
                              <p class="price">→ 9,770원 (원단위 이하 절사)</p>
                              <p>청구금액 (vat 제외) = 9,770/1.1= 8,881.818182원</p>
                              <p class="price">→ 8,882원 (소수점 반올림으로 5부터 올림)</p>
                              <strong class="total">사용요금 = 8,882원</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </v-tooltip>
                  </v-btn>
                </div>
                <div class="result">
                  <span class="num">{{ formatNumberWithComma(totalCostInfo.total_cost) }}</span>
                  <span class="unit">원</span>
                </div>
              </v-list-item>
              <v-list-item>
                <div class="result-title">
                  <strong class="name send"> 발송건수 </strong>
                </div>
                <div class="result">
                  <span class="num">{{
                    formatNumberWithComma(totalCostInfo.total_send_count)
                  }}</span>
                  <span class="unit">건</span>
                </div>
              </v-list-item>
              <v-list-item>
                <div class="result-title">
                  <strong class="name success">
                    성공건수
                    <span> (대체성공 포함) </span>
                  </strong>
                </div>
                <div class="result">
                  <span class="num">{{
                    formatNumberWithComma(totalCostInfo.total_success_count)
                  }}</span>
                  <span class="unit">건</span>
                </div>
              </v-list-item>
            </v-list>
            <div class="no-data-area" v-else>
              <div>
                <p>{{ translate('기간 및 채널을 조회해주세요.') }}</p>
              </div>
            </div>
          </div>
          <!-- 조회내역 -->
          <div class="data-table-contents data-table">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회내역') }}
                  <span>{{ formatNumberWithComma(searchResult) }}</span
                  >{{ translate('건') }}
                </strong>
                <v-btn class="tooltip-btn">
                  <v-tooltip
                    class="tooltip-layer"
                    activator="parent"
                    location="bottom start"
                    origin="auto"
                    scroll-strategy="reposition"
                  >
                    <div class="tooltip-area">
                      <strong class="tooltip-title">발송일자</strong>
                      <p class="tooltip-sub-title">
                        발송일자의 채널별 발송통계를 확인할 수 있습니다.
                      </p>
                      <div class="tooltip-sub-area">
                        <ul class="tooltip-detail-list">
                          <li>
                            <p>사용 요금 : <span>성공 건수*채널 요금</span></p>
                            <p class="detail">
                              사용 요금은 소수점 자릿수 그대로 표현되며, 끝자리가 0인 경우 노출
                              제외됩니다
                            </p>
                            <p class="detail">
                              발송일 기준으로 성공 건수에 채널 요금이 가산되어 집계됩니다.<br />
                              e.g. 9/1 23:59:58 발송 후 9/2 00:00:01에 메시지 수신할 경우 9/1 성공
                              건수로 집계됩니다.
                            </p>
                          </li>
                          <li>
                            <div class="paragraph">
                              <p>발송건수 : 전체 발송 건수</p>
                              <p>성공건수 : 발송 성공 건수</p>
                              <p>실패건수 : 발송 실패 건수</p>
                              <p>
                                미확인 건수 : 통신사로부터 발송 성공 및 실패여부가 확인되지 않은
                                건수
                              </p>
                            </div>
                            <p class="detail">
                              미확인 건수는 발송일자 기준 24시간 이후부터 확인 가능하며 수신자 위치,
                              단말기의 On/Off 상태 등에 따라 확인이 지연될 수 있습니다.
                            </p>
                          </li>
                          <li>
                            <div class="paragraph">
                              <p>대체채널 : <span>발송 실패 시 다른 채널로 대체하여 발송</span></p>
                              <p>대체 채널 요금 : <span>대체 성공*대체 채널 요금</span></p>
                            </div>
                            <p class="detail">
                              대체 채널 요금은 소수점 자릿수 그대로 표현되며, 끝자리 0인 경우 노출
                              제외됩니다.
                            </p>
                            <p class="detail">
                              발송일 기준으로 대체 성공 건수에 대체 채널 요금이 가산되어 집계됩니다.
                              e.g. 9/1 23:59:58 발송 후 9/2 00:00:01에 대체 메시지 수신할 경우 9/1
                              대체 성공 건수로 집계됩니다.
                            </p>
                          </li>
                          <li>
                            <div class="paragraph">
                              <p>
                                대체 성공 :
                                <span>채널 발송 실패 시 다른 채널로 대체 발송 시 성공 건수</span>
                              </p>
                              <p>
                                대체 실패 :
                                <span>채널 발송 실패 시 다른 채널로 대체 발송 시 실패 건수</span>
                              </p>
                              <p>성공률 : <span>{(성공 건수+대체 성공)/발송 건수}*100</span></p>
                            </div>
                            <p class="detail">소수점 발생 시 절사하여 표기됩니다.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </v-tooltip>
                </v-btn>
              </div>
              <div class="data-table-util">
                <v-select
                  v-model="itemsPerPage"
                  :items="itemsPerList"
                  item-title="text"
                  item-value="value"
                ></v-select>
                <v-btn flat @click="downloadExcel">
                  {{ translate('엑셀 다운로드') }}
                </v-btn>
              </div>
            </div>
            <v-dialog v-model="excelDialog" class="dialog-layer search-error-layer" persistent>
              <v-card class="gsr-popup">
                <div class="gsr-popup-icon">
                  <i></i>
                </div>
                <p class="gsr-popup-text">
                  조회된 내역이 없습니다. 엑셀 다운로드는 조회된 데이터가 있을 때만 가능합니다.
                  조건을 다시 설정하여 조회해 주세요.
                </p>
                <div class="gsr-popup-btn">
                  <v-btn ref="confirmBtn" class="confirm" @click="closeExcelDialog">확인</v-btn>
                </div>
              </v-card>
            </v-dialog>
            <v-data-table
              :headers="dataTableHeader"
              :items="staticList"
              :items-per-page="itemsPerPage"
              hide-default-footer
              :fixed-header="true"
              :class="dataTableHeader.length > 12 ? 'scroll-x' : ''"
              v-model:page="currentPage"
              @page-count="handlePageCount"
            >
              <template #no-data>
                <p>해당 내역이 없습니다.</p>
              </template>
              <template #item.cost="{ item }">
                <span>{{ formatNumberWithComma(item.cost) }}</span>
              </template>
              <template #item.send_count="{ item }">
                <span>{{ formatNumberWithComma(item.send_count) }}</span>
              </template>
              <template #item.success_count="{ item }">
                <span>{{ formatNumberWithComma(item.success_count) }}</span>
              </template>
              <template #item.fail_count="{ item }">
                <span>{{ formatNumberWithComma(item.fail_count) }}</span>
              </template>
              <template #item.unconfirmed_count="{ item }">
                <span>{{ formatNumberWithComma(item.unconfirmed_count) }}</span>
              </template>
              <template #item.substitute_fail_count="{ item }">
                <span>{{ formatNumberWithComma(item.substitute_fail_count) }}</span>
              </template>
              <template #item.success_rate="{ item }">
                <span>{{ item.success_rate }}%</span>
              </template>
              <template #item.substitute_cost="{ item }">
                <span v-if="item.substitute_cost === 0">{{ '-' }}</span>
                <span v-else>{{ formatNumberWithComma(item.substitute_cost) }}</span>
              </template>
            </v-data-table>
            <v-pagination
              v-if="staticList.length > 0 && totalPages > 1"
              rounded="circle"
              :total-visible="6"
              v-model="currentPage"
              :length="totalPages"
            >
            </v-pagination>
            <v-dialog v-model="errorDialog" class="dialog-layer search-error-layer" persistent>
              <v-card class="gsr-popup">
                <div class="gsr-popup-icon">
                  <i></i>
                </div>
                <p class="gsr-popup-text">
                  {{ translate(errorMessage) }}
                </p>
                <div class="gsr-popup-btn">
                  <v-btn ref="confirmBtn" class="confirm" @click="closeErrorDialog">확인</v-btn>
                </div>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-tabs-window-item>
  <GSLoading v-if="visibleLoading"></GSLoading>
</template>
<script setup lang="ts">
/* Global */
import { ref, watch, computed, onMounted, onUnmounted, nextTick } from 'vue'
import useAxios from '@/core/plugins/AxiosApi'
import { useI18n } from 'vue-i18n'
import { commonResponse, commonError } from '@/core/plugins/global-plugin'
import { useHeaderState } from '@/core/plugins/global-variable'
import { formatNumberWithComma } from '@/core/plugins/global-util'

/* Specific feature */
import { DataTableHeader } from 'vuetify'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

/* UI Component */
import GSButton from '@/components/ui/GSButton.vue'
import GSLoading from '@/components/ui/GSLoading.vue'
import * as Constants from '@/constants/index'
/* ================================================== */

const { t, te } = useI18n()
const i18n = useI18n()

i18n.locale.value = 'kr'

// @ts-ignore
const translate = (text: string) => {
  if (te(text)) {
    return t(text)
  } else {
    return text
  }
}

interface errorObject {
  redirectURL: string
  message: string
  icon: string
  callBack: string
}

interface Workspace {
  workspace_id: string
  workspace_name: string
}

interface TotalCostInfo {
  total_cost: number
  total_substitute_cost: string
  total_send_count: number
  total_success_count: number
}

interface StaticList {
  log_date: string // 발송일자 (string)
  channels: string // 채널 (string)
  cost: number // 사용금액 (float)
  send_count: number // 발송건수 (int)
  success_count: number // 성공건수 (int)
  fail_count: number // 실패건수 (int)
  unconfirmed_count: number // 미확인 건수 (int)
  substitute_channels: string | null // 대체채널 (string | null)
  substitute_success_count: number // 대체 성공 건수 (int)
  substitute_fail_count: number // 대체 실패 건수 (int)
  substitute_cost: number // 대체 발송 비용 (float)
  success_rate: number // 성공률 (float)
  workspaces: string // workspace (string)
}

const { openProfileMenu, selectedOrganizationName, selectedOrganizationId } = useHeaderState()
const { $axios } = useAxios()
/* ================================================== */

/* Variable declaration function */
const getTodayDate = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

const resetTime = (date: Date) => {
  const resetDate = new Date(date)
  resetDate.setHours(0, 0, 0, 0)

  return resetDate
}

const resetDateValue = (dateString: string): Date => {
  const date = new Date(dateString)
  date.setHours(0, 0, 0, 0)

  return date
}
/* ================================================== */

/* DOM manipulation variable */
const activeIndex = ref<number | null>()
const isDisabled = ref(false)
const dialog = ref(false)
const excelDialog = ref(false)
const errorDialog = ref(false)
const openDatePicker = ref(false)
const isChannelOpen = ref(false)
const openSearchWorkspace = ref(false)
const showAutocomplete = ref(false)
const isAutocompleteVisible = ref(true)
const returnObj = ref<errorObject | null>()
const errorMessage = ref<string>('')

/* API request field & logic variable */
const searchStartDate = ref(getTodayDate())
const searchEndDate = ref(getTodayDate())
const selectedStartDate = ref<Date | null>(resetTime(new Date()))
const selectedEndDate = ref<Date | null>(resetTime(new Date()))
const selectedPeriod = ref<string>('today')
const selectedChannel = ref('')
const selectedWorkspace = ref<Workspace | null>(null)

/* API response variable */
const workspaceList = ref<Workspace[]>([])
const preWorkspace = ref<Workspace | null>(null)
const totalCostInfo = ref<TotalCostInfo | null>(null)
const staticList = ref<StaticList[]>([])

/* Data variable */
const datePeriod = ref([
  { value: 'today', text: '오늘' },
  { value: '1m', text: '1개월' },
  { value: '3m', text: '3개월' },
  { value: '6m', text: '6개월' }
])
const channelList = ref(['전체', 'SMS', 'LMS', 'MMS'])

/* Display variable */
const searchResult = ref<number>(0)
const totalInfoStartDate = ref<string>('')
const totalInfoEndDate = ref<string>('')
const visibleLoading = ref<boolean>(false)

/* Excel variable */
const excelOrganization = ref<string | undefined>(undefined)
const excelChannel = ref<string | undefined>(undefined)
const excelStartDate = ref<string | undefined>(undefined)
const excelEndDate = ref<string | undefined>(undefined)

/* Reference variable */
const refWorkspaceList = ref<HTMLElement | null>(null)
const refChannel = ref<HTMLInputElement | null>(null)

/* Table & Pagination variable */
const itemsPerList = ref([
  { text: '10개씩 보기', value: 10 },
  { text: '30개씩 보기', value: 30 },
  { text: '50개씩 보기', value: 50 },
  { text: '100개씩 보기', value: 100 }
])
const itemsPerPage = ref<number>(10)
const currentPage = ref(1)
const pageCount = ref(0)
const totalPages = computed(() => Math.ceil(staticList.value.length / itemsPerPage.value))
const dataTableHeader = ref<DataTableHeader>([
  {
    align: 'center',
    title: '발송일자',
    value: 'log_date',
    sortable: false
  },
  {
    align: 'center',
    title: '채널',
    value: 'channels',
    sortable: false
  },
  {
    align: 'end',
    title: '사용금액(원)',
    value: 'cost',
    sortable: false
  },
  {
    align: 'end',
    title: '발송건수',
    value: 'send_count',
    sortable: false
  },
  {
    align: 'end',
    title: '성공건수',
    value: 'success_count',
    sortable: false
  },
  {
    align: 'end',
    title: '실패건수',
    value: 'fail_count',
    sortable: true
  },
  {
    align: 'end',
    title: '미확인 건수',
    value: 'unconfirmed_count',
    sortable: false
  },
  {
    align: 'end',
    title: '대체채널 요금(원)',
    replaceChannelCharge: '-',
    value: 'substitute_cost',
    sortable: false
  },
  {
    align: 'center',
    title: '대체채널',
    value: 'substitute_channels',
    sortable: false
  },
  {
    align: 'end',
    title: '대체성공',
    value: 'substitute_success_count',
    sortable: false
  },
  {
    align: 'end',
    title: '대체실패',
    value: 'substitute_fail_count',
    sortable: true
  },
  {
    align: 'end',
    title: '성공율(%)',
    value: 'success_rate',
    sortable: true
  },
  {
    align: 'start',
    title: 'Workspace',
    value: 'workspaces',
    sortable: false
  }
])

/* ================================================== */

/* 기간 버튼 그룹 */
const selectedPeriodBtn = (value: string, index: number | null) => {
  selectedPeriod.value = value
  const today = new Date()
  let calculatedStartDate: Date

  switch (selectedPeriod.value) {
    case 'today':
      calculatedStartDate = new Date(today)
      break
    case '1m':
      calculatedStartDate = new Date(today)
      calculatedStartDate.setMonth(today.getMonth() - 1)
      break
    case '3m':
      calculatedStartDate = new Date(today)
      calculatedStartDate.setMonth(today.getMonth() - 3)
      break
    case '6m':
      calculatedStartDate = new Date(today)
      calculatedStartDate.setMonth(today.getMonth() - 6)
      break
    case '':
      return
    default:
      calculatedStartDate = new Date(today)
      break
  }

  selectedStartDate.value = resetTime(calculatedStartDate)
  selectedEndDate.value = resetTime(today)
  searchStartDate.value = changeDate(selectedStartDate.value)
  searchEndDate.value = changeDate(selectedEndDate.value)

  if (index !== null) {
    activeDataBtn(index)
  }
}

const daysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate()
}

const formatDateInput = (e: Event, type: string) => {
  let input = (e.target as HTMLInputElement).value.replace(/\D/g, '')

  if (input.length > 4) {
    input = `${input.slice(0, 4)}-${input.slice(4)}`
  }

  if (input.length > 7) {
    input = `${input.slice(0, 7)}-${input.slice(7)}`
  }

  if (input.length > 10) {
    input = input.slice(0, 10)
  }

  const year = parseInt(input.slice(0, 4), 10)
  const month = parseInt(input.slice(5, 7), 10)
  const day = parseInt(input.slice(8, 10), 10)

  if (month && (month < 1 || month > 12)) {
    input = `${input.slice(0, 4)}-`
  }

  if (month && day && (day < 1 || day > daysInMonth(year, month))) {
    input = `${input.slice(0, 7)}-`
  }

  if (type === 'start') {
    searchStartDate.value = input
  } else if (type === 'end') {
    searchEndDate.value = input
  }
}

const activeDataBtn = (index: number) => {
  if (isDisabled.value) return
  activeIndex.value = index
  isDisabled.value = true

  setTimeout(() => {
    activeIndex.value = null
    isDisabled.value = false
  }, 300)
}

/* ================================================== */

/* 달력 */
const isDateSelected = computed(() => {
  return searchStartDate.value != '' || searchEndDate.value != ''
})

const clearDate = () => {
  searchStartDate.value = ''
  searchEndDate.value = ''
  selectedStartDate.value = null
  selectedEndDate.value = null
}

const changeDate = (date: Date | null) => {
  return date ? date.toLocaleDateString('en-CA') : ''
}

const cofirmDate = () => {
  const sdate = new Date(searchStartDate.value)
  const edate = new Date(searchEndDate.value)

  if (sdate > edate) {
    searchStartDate.value = changeDate(selectedStartDate.value)
    searchEndDate.value = ''
  } else {
    searchStartDate.value = changeDate(selectedStartDate.value)
    searchEndDate.value = changeDate(selectedEndDate.value)
  }
  openDatePicker.value = false
}

const setToday = () => {
  const today = new Date()
  selectedStartDate.value = resetTime(today)
  selectedEndDate.value = resetTime(today)
}

const resetDate = () => {
  selectedPeriod.value = 'today'
  const today = new Date()
  selectedStartDate.value = resetTime(today)
  selectedEndDate.value = resetTime(today)
}

const closeDate = () => {
  if (searchStartDate.value === '' && searchEndDate.value === '') {
    selectedStartDate.value = null
    selectedEndDate.value = null
  } else if (searchStartDate.value === '') {
    selectedStartDate.value = null
    selectedEndDate.value = resetDateValue(searchEndDate.value)
  } else if (searchEndDate.value === '') {
    selectedStartDate.value = resetDateValue(searchStartDate.value)
    selectedEndDate.value = null
  } else {
    selectedStartDate.value = resetDateValue(searchStartDate.value)
    selectedEndDate.value = resetDateValue(searchEndDate.value)
  }
  openDatePicker.value = false
}

const onClickDateInput = () => {
  openDatePicker.value = true
}

watch(searchStartDate, (newDateStr) => {
  if (newDateStr) {
    const date = new Date(newDateStr)
    selectedStartDate.value = resetTime(date)
  } else {
    selectedStartDate.value = null
  }
})

watch(searchEndDate, (newDateStr) => {
  if (newDateStr) {
    const date = new Date(newDateStr)
    selectedEndDate.value = resetTime(date)
  } else {
    selectedEndDate.value = null
  }
})

watch(openDatePicker, () => {
  if (selectedStartDate.value !== null && selectedEndDate.value !== null) {
    selectedStartDate.value = resetTime(selectedStartDate.value)
    selectedEndDate.value = resetTime(selectedEndDate.value)
    if (selectedStartDate.value > selectedEndDate.value) {
      selectedStartDate.value = resetDateValue(searchStartDate.value)
      searchEndDate.value = ''
      selectedEndDate.value = null
    } else {
      if (searchStartDate.value == '') {
        selectedStartDate.value = null
      } else {
        selectedStartDate.value = resetDateValue(searchStartDate.value)
      }
      if (searchEndDate.value == '') {
        selectedEndDate.value = null
      } else {
        selectedEndDate.value = resetDateValue(searchEndDate.value)
      }
    }
    // else {
    //   searchStartDate.value = changeDate(selectedStartDate.value)
    //   searchEndDate.value = changeDate(selectedEndDate.value)
    // }
    // selectedStartDate.value = resetDateValue(searchStartDate.value)
    // selectedEndDate.value = resetDateValue(searchEndDate.value)
  }
})
/* ================================================== */

/* 채널 리스트 */
const clickChannel = () => {
  if (openDatePicker.value) {
    isChannelOpen.value = true
    openDatePicker.value = false
  }
  if (openProfileMenu.value) {
    isChannelOpen.value = true
    openProfileMenu.value = false
  }
}

watch(isChannelOpen, (newVal) => {
  if (!newVal) {
    setTimeout(() => {
      if (openDatePicker.value) {
        isChannelOpen.value = true
        openDatePicker.value = false
      }

      if (openProfileMenu.value) {
        isChannelOpen.value = true
        openProfileMenu.value = false
      }
    }, 0)
  }
})
/* ================================================== */

/* Workspace 리스트 */
const onClickOutside = () => {
  openSearchWorkspace.value = false
  if (selectedWorkspace.value === null) {
    selectedWorkspace.value = preWorkspace.value
  }
  showAutocomplete.value = false
}

const onKeydownWorkspace = (event: KeyboardEvent) => {
  if (event.key === 'Escape' || event.keyCode === 27) {
    openSearchWorkspace.value = false
    if (selectedWorkspace.value === null) {
      selectedWorkspace.value = preWorkspace.value
    }
  }
}

function handleMenuOpen(isOpen: boolean) {
  if (isOpen) {
    setTimeout(() => {
      showAutocomplete.value = true
      nextTick(() => {
        refWorkspaceList.value?.focus()
      })
    }, 280)
  } else {
    showAutocomplete.value = false
  }
}

watch(selectedWorkspace, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue !== null) {
      openSearchWorkspace.value = false
    } else {
      preWorkspace.value = oldValue
    }
  }
})

watch(isAutocompleteVisible, (newValue, oldValue) => {
  if (newValue === false) {
    isAutocompleteVisible.value = true
  }
})

watch(selectedOrganizationId, (newValue, oldValue) => {
  if (newValue !== '') {
    fetchGetWorkspaceList()
    itemsPerPage.value = 10
    searchResult.value = 0
    selectedChannel.value = channelList.value[0]
    selectedPeriodBtn('today', null)
    staticList.value = []
    totalCostInfo.value = null
  }
})
/* ================================================== */

/* Dialog */
const openDialog = () => {
  dialog.value = true
}
const closeDialog = () => {
  dialog.value = false
}

const openExcelDialog = () => {
  excelDialog.value = true
}
const closeExcelDialog = () => {
  excelDialog.value = false
}

const openErrorDialog = () => {
  errorDialog.value = true
}
const closeErrorDialog = () => {
  errorDialog.value = false
  if (returnObj.value?.redirectURL) {
    window.location.href = returnObj.value.redirectURL
  }
}
/* ================================================== */

/* 유효성 검사 */
const convertValidDate = (dateStr: string): boolean => {
  const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
  return dateRegex.test(dateStr)
}

const searchValidate = (startDate: string, endDate: string): boolean => {
  if (!convertValidDate(startDate) || !convertValidDate(endDate)) {
    return false
  }

  const sdate = new Date(startDate)
  const edate = new Date(endDate)

  if (sdate > edate) {
    return false
  }

  const oneYearInMs = 365 * 24 * 60 * 60 * 1000
  const dateDifference = edate.getTime() - sdate.getTime()

  if (dateDifference > oneYearInMs) {
    return false
  }

  return true
}
/* ================================================== */

/* 화면기능 & 화면출력 */
const searchStatistics = () => {
  if (searchValidate(searchStartDate.value, searchEndDate.value)) {
    fetchGetStatisticsList()
  } else {
    openDialog()
  }
}

const totalInfoFormatDate = (dateStr: string): string => {
  const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/

  if (!dateRegex.test(dateStr)) {
    throw new Error('Invalid date format. Please use YYYY-MM-DD format.')
  }

  return dateStr.replace(/-/g, '. ')
}

const closeAllOverlays = () => {
  openDatePicker.value = false
  openSearchWorkspace.value = false
  if (selectedWorkspace.value === null) {
    selectedWorkspace.value = preWorkspace.value
  }
  if (refChannel.value && refChannel.value.blur) {
    refChannel.value.blur()
  }
}
/* ================================================== */

/* 테이블 */
const handlePageCount = (newPageCount: number) => {
  pageCount.value = newPageCount
}
/* ================================================== */

/* 엑셀 */
const replaceDate = (dateStr: string): string => {
  return dateStr.replace(/-/g, '')
}

const downloadExcel = async () => {
  if (staticList.value.length === 0) {
    openExcelDialog()
    return
  }

  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('발송통계')

  worksheet.columns = [
    { header: '발송일자', key: 'log_date', width: 15 },
    { header: '채널', key: 'channels', width: 15 },
    { header: '사용금액(원)', key: 'cost', width: 20 },
    { header: '발송건수', key: 'send_count', width: 15 },
    { header: '성공건수', key: 'success_count', width: 15 },
    { header: '실패건수', key: 'fail_count', width: 15 },
    { header: '미확인 건수', key: 'unconfirmed_count', width: 15 },
    { header: '대체채널 요금(원)', key: 'substitute_cost', width: 20 },
    { header: '대체채널', key: 'substitute_channels', width: 15 },
    { header: '대체성공', key: 'substitute_success_count', width: 15 },
    { header: '대체실패', key: 'substitute_fail_count', width: 15 },
    { header: '성공율(%)', key: 'success_rate', width: 15 },
    { header: 'Workspace', key: 'workspaces', width: 20 }
  ]

  staticList.value.forEach((item) => {
    worksheet.addRow(item)
  })

  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true }
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E9E9E9' }
    }
    cell.border = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } }
    }
  })

  const totalRows = worksheet.rowCount

  for (let rowIndex = 2; rowIndex <= totalRows; rowIndex++) {
    const row = worksheet.getRow(rowIndex)

    row.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      }
    })
  }

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })

  var orgFileName = `(Sendy)발송통계_${excelOrganization.value}_${excelChannel.value}_${excelStartDate.value}~${excelEndDate.value}.xlsx`
  var excelFileName = orgFileName.replace(/[\\?%*:|"<>]/g, '')

  saveAs(blob, excelFileName)
}
/* ================================================== */

/* API */
const fetchGetWorkspaceList = async () => {
  visibleLoading.value = true
  const requestParam = {
    pageable: false,
    q: `organization_id eq ${selectedOrganizationId.value}`
  }
  await $axios
    .get('/api/workspaces', {
      params: requestParam,
      headers: {
        'x-tenant-id': '019145d8-0390-7c28-85c6-cc16452b7106'
      }
    })
    .then((response) => {
      const responseData = commonResponse(response)
      workspaceList.value = responseData.data
      var tempObj = { workspace_id: '', workspace_name: '전체' }
      workspaceList.value.unshift(tempObj)
      selectedWorkspace.value = workspaceList.value[0]
      visibleLoading.value = false
    })
    .catch((error) => {
      console.error(error)
      returnObj.value = commonError(error)
      errorMessage.value = returnObj.value.message
      visibleLoading.value = false
      openErrorDialog()
    })
}

const fetchGetStatisticsList = async () => {
  visibleLoading.value = true
  const requestParam = {
    organization_id: selectedOrganizationId.value,
    ...(selectedWorkspace.value?.workspace_id !== '' && { workspace_id: selectedWorkspace.value }),
    start_date: searchStartDate.value,
    end_date: searchEndDate.value,
    channel_id: selectedChannel.value == '전체' ? 'UMS' : selectedChannel.value
  }
  await $axios
    .get('/api/message/statics', {
      params: requestParam,
      headers: {
        'x-tenant-id': '019145d8-0390-7c28-85c6-cc16452b7106'
      }
    })
    .then((response) => {
      totalCostInfo.value = response.data.data.total
      staticList.value = response.data.data.rows
      totalInfoStartDate.value = totalInfoFormatDate(searchStartDate.value)
      totalInfoEndDate.value = totalInfoFormatDate(searchEndDate.value)
      excelOrganization.value = selectedOrganizationName.value
      excelChannel.value = selectedChannel.value == '전체' ? 'SMS/LMS/MMS' : selectedChannel.value
      excelStartDate.value = replaceDate(searchStartDate.value)
      excelEndDate.value = replaceDate(searchEndDate.value)
      // 원래 배열을 복사해 두기
      // for (let i = 0; i < 99; i++) {
      //   var originalList = {
      //     log_date: '1', // 발송일자 (string)
      //     channels: '1', // 채널 (string)
      //     workspaces: '1', // workspace (string)
      //     cost: i, // 사용금액 (float)
      //     send_count: i, // 발송건수 (int)
      //     success_count: i, // 성공건수 (int)
      //     fail_count: i, // 실패건수 (int)
      //     substitute_channels: 'a', // 대체채널 (string | null)
      //     substitute_success_count: i, // 대체 성공 건수 (int)
      //     substitute_fail_count: i, // 대체 실패 건수 (int)
      //     substitute_cost: i, // 대체 발송 비용 (float)
      //     unconfirmed_count: i, // 미확인 건수 (int)
      //     success_rate: i // 성공률 (float)
      //   }
      //   staticList.value.push(originalList)
      // }
      searchResult.value = staticList.value.length
      visibleLoading.value = false
    })
    .catch((error) => {
      console.error(error)
      returnObj.value = commonError(error)
      errorMessage.value = returnObj.value.message
      visibleLoading.value = false
      openErrorDialog()
    })
}
/* ================================================== */

/* Lifecycle Hooks  */
onUnmounted(() => {
  window.removeEventListener('scroll', closeAllOverlays)
})

onMounted(() => {
  selectedChannel.value = channelList.value[0]
  selectedPeriodBtn('today', null)
  window.addEventListener('scroll', closeAllOverlays)
})
</script>
<script lang="ts">
export default {}
</script>
